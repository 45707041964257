<template>
  <!--   <v-scale-screen width="1920" height="1080">-->
  <div class="homeBox" :class="background ? 'background' : ''">
    <Top v-show="!isMobile || (isMobile && !isNoShow)" />
    <Nav v-show="!isMobile || (isMobile && !isNoShow)" />
    <div class="content">
      <router-view/>
      <template v-if="link.includes('preview')">
        <iframe class="preview-layer" ref="observer"/>
        <div class="preview-layer">
          <div class="item" v-for="(item, index) in nums" :key="index">
            <p><span>预览</span><span>预览</span><span>预览</span></p>
          </div>
        </div>
      </template>
    </div>
    <Footer v-show="!isMobile || (isMobile && !isNoShow)" />
  </div>
  <!--   </v-scale-screen>-->
</template>

<script>
import $ from 'jquery';
import Top from "@/components/Top";
import Nav from "@/components/Nav";
import Footer from "@/components/Footer";

import {debounce} from "@/utils";

export default {
  name: "index",
  components: {Nav, Top, Footer},
  data() {
    return {
      isMobile: false,
      isPad: false,
      isNoShow:false, // 是否展示对应的tab
      background: false,
      nums: 0,
      link: "",
    };
  },
  watch: {
    $route() {
      window.location.reload(); //监测到路由发生跳转时刷新一次页面
    },
  },
  created() {
    console.log("window.location.href", window.location.href);
    this.link = window.location.href;

    this.isMobile = window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    this.isPad = window.navigator.userAgent.match(
        /(pad|pod|iPod|iPad)/i
    );
    if(this.isPad){
      this.isMobile = false
    }
  },
  mounted() {
    console.log("this.$route.path",this.$route.path)
     /*不需要顶部导航的页面*/
     let pathArr = [
          '/preview/insurance/disclosure', // 投连险信息披露-预览
          '/disclosure', // 投连险信息披露
          '/disclosureDetails', // 投连险信息披露详情
          '/announcement', // 万能险利率公告
          '/interestrateHistory', // 查询公布利率
          '/solvencyCapacity/AZ01005', // 偿付能力信息
          '/azContent/introduce/AZ022', // 隐私政策
      ]
    this.isNoShow = pathArr.includes(this.$route.path)
    if(this.isPad){
      this.isNoShow = false
    }
    console.log("this.isNoShow",this.isNoShow)
    if (this.link.includes("preview")) {
      this.nums=parseInt(this.$refs.observer.clientHeight / 700) + 1
      this.$refs.observer.contentWindow.onresize = debounce(() => {
        this.nums = parseInt(this.$refs.observer.clientHeight / 700) + 1;
      }, 150);
    }
    this.orient();
  },
  methods: {
    orient: function () {
      /*不需要顶部导航的页面*/
      let pathArr = [
            '/preview/insurance/disclosure', // 投连险信息披露-预览
            '/disclosure', // 投连险信息披露
            '/disclosureDetails', // 投连险信息披露详情
            '/announcement', // 万能险利率公告
            '/interestrateHistory', // 查询公布利率
            '/solvencyCapacity/AZ01005', // 偿付能力信息
            '/azContent/introduce/AZ022', // 隐私政策
        ]
        //需要缩小的页面
        let suofangArr = [
            '/home', // home页
            '/productsServices/health', // 享健康专区
            '/productsServices/vipZone', // 会员权益俱乐部专区
            '/productsServices/vipText/AZ02501', // 会员权益俱乐部专区-贵宾俱乐部
            '/productsServices/vipText/AZ02502', // 会员权益俱乐部专区-青年俱乐部
            '/productsServices/vipText/AZ02503', // 会员权益俱乐部专区-少儿俱乐部
            '/azContent/introduce/AZ026', // 安联客服专线
            '/personalCustomer/insuranceAfterSales/AZ020', // 投诉与建议
            '/productsServices/selfHelp/online', // 在线留言
        ]
      var isMobile = window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if(this.isNoShow){
        isMobile = false;
      }
      console.log("this.$route.paththis.$route.paththis.$route.paththis.$route.paththis.$route.path",this.$route   ,pathArr,pathArr.includes(this.$route.path))
      if(isMobile){
        if(pathArr.includes(this.$route.path)){
          // if (window.screen.width < 1366) { //小于1366屏幕时做屏幕缩放为768
            $('head').remove('[name="viewport"]')
            const meta = document.createElement('meta');
            meta.name = "viewport";
            meta.content = "width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            document.getElementsByTagName('head')[0].appendChild(meta)
          // } else {
          //     $('head').remove('[name="viewport"]')
          // }
        }else if(suofangArr.includes(this.$route.path)){
          $('head').remove('[name="viewport"]')
          const meta = document.createElement('meta');
          meta.name = "viewport";
          meta.content = "width=device-width,intial-scale=0,maximum-scale=0,user-scalable=yes,shrink-to-fit=no"
          document.getElementsByTagName('head')[0].appendChild(meta)
        }else{
          $('head').remove('[name="viewport"]')
          const meta = document.createElement('meta');
          meta.name = "viewport";
          meta.content = "width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          document.getElementsByTagName('head')[0].appendChild(meta)
        }
      }else{
        $('head').remove('[name="viewport"]')
        const meta = document.createElement('meta');
        meta.name = "viewport";
        meta.content = "width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        document.getElementsByTagName('head')[0].appendChild(meta)
        // $('head').remove('[name="viewport"]')
        // $('head').append('<meta name="viewport" content="width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=no">')
      }
      // <meta name="viewport" content="width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
      // <meta name="viewport" content="width=device-width,intial-scale=0,maximum-scale=0,user-scalable=yes,shrink-to-fit=no">
    }
  },
};
</script>

<style scoped lang="less">
.homeBox {
  //min-width: 1034px;
  overflow: scroll;
  //min-height: 600px;
  overflow: auto;
  width:100%;
}

.background {
  /*filter: grayscale(1)*/
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}

.content {
  position: relative;
}

.content .preview-layer {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 80%;
  margin: auto;
  /*text-align: center;*/
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  pointer-events: none;
}

iframe.preview-layer {
  visibility: hidden;
}

.content .preview-layer .item {
  font-size: 65px;
  color: #000;
  opacity: 0.1;
  transform: rotate(-20deg);

  p {
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
}
</style>
