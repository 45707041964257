// 关于安联
export const aboutFirst = [
  {
    link: `/azContent/introduce/${"AZ003"}`,
    img: require("@/static/images/secondaryMenu/about/allianz.png"),
    title: "安联人寿",
  },
  {
    link: `/azContent/introduce/${"AZ002"}`,
    img: require("@/static/images/secondaryMenu/about/allianzCn.png"),
    title: "安联在中国",
  },
  {
    link: `/azContent/introduce/${"AZ001"}`,
    img: require("@/static/images/secondaryMenu/about/World Globe.png"),
    title: "安联集团",
  },
];
export const about = [
  {
    link: ``,
    img: require("@/static/images/secondaryMenu/about/Star Event.png"),
    title: "营销员荣誉榜",
    children: [
      {
        link: `/azContent/introduce/${"AZ00401"}`,
        text: "年度全国优秀营销员",
      },
      {
        link: `/azContent/introduce/${"AZ00402"}`,
        text: "年度新晋升主管",
      },
      {
        link: `/azContent/introduce/${"AZ00403"}`,
        text: "(资深)营业区总监",
      },
    ],
  },
  {
    link: "",
    img: require("@/static/images/secondaryMenu/about/Partner Handshake.png"),
    title: "品牌奇迹",
    children: [
      {
        link: `/azContent/introduce/${"AZ00509"}`,
        text: "品牌奇迹",
      },
      {
        link: `/azContent/introduce/${"AZ00506"}`,
        text: "电动越野赛",
      },
      {
        link: `/azContent/introduce/${"AZ00508"}`,
        text: "奥林匹克全球合作伙伴",
      },
      {
        link: `/azContent/introduce/${"AZ00507"}`,
        text: "电动方程式",
      },
      {
        link: `/azContent/introduce/${"AZ00501"}`,
        text: "安联体育场大家庭",
      },
      {
        link: `/azContent/introduce/${"AZ00503"}`,
        text: "高尔夫",
      },
      {
        link: `/azContent/introduce/${"AZ00502"}`,
        text: "拜仁顶级合作伙伴",
      },
      {
        link: `/azContent/introduce/${"AZ00505"}`,
        text: "现代艺术博物馆",
      },
      {
        link: `/azContent/introduce/${"AZ00504"}`,
        text: "安联全球品牌大使郎朗",
      },
      {
        link: `/azContent/introduce/${"AZ00510"}`,
        text: "安联杯“未来之星”",
      },
    ],
  },
  {
    link: "",
    img: require("@/static/images/secondaryMenu/about/Voice Of The Customer.png"),
    title: "企业社会责任",
    children: [
      {
        link: `/azContent/introduce/${"AZ006"}`,
        text: "绿色安联可持续发展",
      },
    ],
  },
];
// 产品服务
export const services = [
  {
    img: require("@/static/images/secondaryMenu/productsServices/People Connect.png"),
    title: "个人客户",
    children: [
      {
        link: `/personalCustomer/insuranceAfterSales/${'AZ01801'}`,
        text: "保险购买指南",
      },
      {
        link: `/personalCustomer/insuranceAfterSales/${'AZ01802'}`,
        text: "保单售后服务",
      },
      {
        link: `/personalCustomer/insuranceAfterSales/${'AZ01803'}`,
        text: "安联理赔服务",
      },
      {
        link: `/personalCustomer/insuranceAfterSales/${'AZ01804'}`,
        text: "核保体检机构查询",
      },
      {
        // link: `/azContent/introduce/${'AZ01805'}`,
        link:"https://www.allianz.com.cn/allianz/fad37dd449c64d24a9f4579fc03f527f.pdf",
        text: "昂贵及质重医院查询",
      },
    ],
  },
  {
    img: require("@/static/images/secondaryMenu/productsServices/People Collaboration.png"),
    title: "团体客户",
    children: [
      {
        link: "https://pts.allianz.com.cn/azclportal/faces/hrlogin?_adf.ctrl-state=15hlvl9sm4_19",
        text: "团险客户查询",
      },
      {
        link: `/personalCustomer/insuranceAfterSales/${'AZ01901'}`,
        text: "保单售后服务",
      },
      // {
      //   link: `/personalCustomer/insuranceAfterSales/${'AZ01902'}`,
      //   text: "保单查询",
      // },
      {
        link: "https://pts.allianz.com.cn/zdal/merchant-web/#/login",
        text: "“享•福利”团险自助平台",
      },
      {
        link: "https://my.mshasia.com/msh/Pages/Login.aspx",
        text: "安康至臻客户登录",
      },
      {
        link: "https://pts.allianz.com.cn/wsm/login",
        text: "关爱全家自助服务",
      },
    ],
  },
  {
    img: require("@/static/images/secondaryMenu/productsServices/Media.png"),
    title: "在线自助服务",
    children: [
      {
        link: "https://pts.allianz.com.cn/azclportal/faces/cplogin",
        text: "网上客户服务中心",
      },
      // {
      //   link: "",
      //   text: "电子签约",
      // },
      {
        // link: "/productsServices/selfHelp/online",
        link: "/productsServices/selfHelp/online",
        text: "在线留言",
      },
    ],
  },
  {
    img: require("@/static/images/secondaryMenu/productsServices/Arrow Direction Path.png"),
    title: "其他服务",
    children: [
      {
        link: "/productsServices/health",
        text: "享健康专区",
      },
      {
        link: "/productsServices/vipZone",
        text: "会员权益俱乐部专区",
      },
      {
        link: `/azContent/introduce/${'AZ026'}`,
        text: "安联客服专线",
      },
      {
        link: "/consumer",
        text: "消费者教育及风险提示专栏",
      },
      {
        link: `/personalCustomer/insuranceAfterSales/${'AZ020'}`,
        text: "投诉与建议",
      },
    ],
  },
];
// 产品中心
export const product = [
  {
    img: require("@/static/images/secondaryMenu/productCenter/Person.png"),
    title: "个人保险",
    children: [
      {
        link: `/increaseWealth/${0}`,
        index:0,
        title: "财富管理",
        text: "财富之路，行稳致远",
      },
      {
        index:1,
        link: `/increaseWealth/${1}`,
        title: "成长快乐",
        text: "成就孩子光明未来",
      },
      {
        index:2,
        link: `/increaseWealth/${2}`,
        title: "幸福晚年",
        text: "维持和提高老年生活水平",
      },
      {
        index:3,
        link: `/increaseWealth/${3}`,
        title: "安心保障",
        text: "防范风险传递爱",
      },
      {
        index:4,
        link: "",
        title: "健康生活",
        text: "为您的健康保驾护航",
      },
      {
        index:5,
        link: "",
        title: "出行无忧",
        text: "全面多样的意外保险",
      },
      {index:6,
        link: "",
        title: "财富传承",
        text: "一生成就世代相传",
      },
    ],
  },

  {
    img: require("@/static/images/secondaryMenu/productCenter/Person Heart People Love.png"),
    title: "热销新品",
    children: [
      // {
      //   link: "",
      //   title: "安联逸升优享系列",
      //   text: "赢丰裕未来 享精彩人生",
      // },
      // {
      //   link: "",
      //   title: "安联盛世尊享系列",
      //   text: "传承有道 延续家族荣光",
      // },
      // {
      //   link: "",
      //   title: "安联超级随心系列",
      //   text: "定制方案 随心选择",
      // },
      // {
      //   link: "",
      //   title: "安联关爱全家企业员工福利保险产品计划",
      //   text: "为员工和家属专属定制",
      // },
      // {
      //   link: "",
      //   title: "安联超级随心幸福魔方保险产品计划",
      //   text: "为生活景上添花",
      // },
    ],
  },
  {
    img: require("@/static/images/secondaryMenu/productCenter/Person.png"),
    title: "团体保险",
    children: [
      {
        link: `/increaseWealth/${1}`,
        index: 0,
        title: "综合意外",
        text: "综合保障 抵御意外风险",
        type: "2",
        categoryType: "2"
      },
      {
        index: 3,
        link: `/increaseWealth/${4}`,
        title: "团体年金",
        text: "吸引、保留、激励人才",
        type: "2",
        categoryType: "2"
      },

      {
        index: 1,
        link: `/increaseWealth/${2}`,
        title: "补充医疗",
        text: "丰富多元的医疗保障",
        type: "2",
        categoryType: "2"
      },
      {
        index: 4,
        link: `/increaseWealth/${5}`,
        title: "团体境外旅行",
        text: "商旅无忧",
        type: "2",
        categoryType: "2"
      },
      {
        index: 2,
        link: `/increaseWealth/${3}`,
        title: "高端臻享",
        text: "尊贵就医体验",
        type: "2",
        categoryType: "2"
      },
      {
        index: 5,
        link: `/increaseWealth/${6}`,
        title: "灵活定制",
        text: "按企业需求定制",
        type: "2",
        categoryType: "2"
      }
    ],
  },
];
// 新闻公告
export const news = [
  {
    img: require("@/static/images/secondaryMenu/news/Apartment Building.png"),
    text: "公司新闻",
    link: `/news/${'AZ01501'}`,
  },
  {
    img: require("@/static/images/secondaryMenu/news/Broken Mirror.png"),
    text: "客服公告",
    link: `/news/${'AZ01502'}`,
  },
  {
    img: require("@/static/images/secondaryMenu/news/Calendar Travel.png"),
    text: "公司公告",
    link: `/news/${'AZ01503'}`,
  },
  {
    img: require("@/static/images/secondaryMenu/news/tender.png"),
    text: "招标公告",
    link: `/news/${'AZ01504'}`,
  },
  {
    img: require("@/static/images/secondaryMenu/news/Bereaved Dependent Pension.png"),
    text: "投连险信息披露",
    link: "/disclosure",
  },
  {
    img: require("@/static/images/secondaryMenu/news/Balance Scale.png"),
    text: "万能险利率公告",
    link: "/announcement",
  },
  {
    img: require("@/static/images/secondaryMenu/news/Book.png"),
    text: "累积生息利率及贷款利率公告",
    link: `/azContent/introduce/${'AZ01506'}`,
  },
  // {
  //   img: require("@/static/images/secondaryMenu/news/Book.png"),
  //   text: "分红险信息披露",
  //   link: `/azContent/introduce/${'AZ01507'}`,
  // },
  // {
  //   img: require("@/static/images/secondaryMenu/news/Book.png"),
  //   text: "新型产品-分红险信息披露",
  //   link: `/azContent/introduce/${'AZ01013'}`,
  // },
];
// 在线投保
export const onlineInsurance = [
  {
    link: "https://sales.allianz.com.cn/ewebsite/pages/home/home.html",
    img: require("@/static/images/secondaryMenu/onlineInsurance/At Icon.png"),
    title: "点击进入安联在线保",
  },
  {
    link: `/azContent/introduce/${"AZ01006"}`,
    img: require("@/static/images/secondaryMenu/onlineInsurance/Camera.png"),
    title: "互联网保险信息披露",
  },
  {
    link: "https://sales.allianz.com.cn/ewebsite/pages/home/list.html?type=lvxing",
    img: require("@/static/images/secondaryMenu/onlineInsurance/Plane.png"),
    title: "旅行险",
  },
  {
    link: "https://sales.allianz.com.cn/ewebsite/pages/home/list.html?type=ywxing",
    img: require("@/static/images/secondaryMenu/onlineInsurance/Health Stethoscope.png"),
    title: "意外险",
  },
  {
    link: "https://sales.allianz.com.cn/ewebsite/pages/home/list.html?type=jkxing",
    img: require("@/static/images/secondaryMenu/onlineInsurance/Car Accident.png"),
    title: "健康险",
  },
];
//职业生涯
export const career = [
  {
    // link: "https://www.allianzholding.cn/occupation",
    link: "doing",
    img: require("@/static/images/secondaryMenu/career/Home Office.png"),
    title: "员工招聘",
  },
  {
    link: "https://careers.allianz.com/",
    img: require("@/static/images/secondaryMenu/career/Law Internet.png"),
    title: "全球机会",
  },
  {
    link: `/azContent/introduce/${'AZ02401'}`,
    img: require("@/static/images/secondaryMenu/career/Law Job.png"),
    title: "营销员招聘",
  },

];
// 销售通道
export const serviceChannel = [
  {
    link: "/productsServices/selfHelp/online",
    img: require("@/static/images/secondaryMenu/serviceChannel/Paw.png"),
    title: "在线留言",
  },
  // {
  //   link: "",
  //   img: require("@/static/images/secondaryMenu/serviceChannel/Search Document.png"),
  //   title: "万能险利率公告",
  // },
  // {
  //   link: "",
  //   img: require("@/static/images/secondaryMenu/serviceChannel/Shield Check.png"),
  //   title: "健康一点通",
  // },
  {
    link: "https://pts.allianz.com.cn/portal/Account/Login?ReturnUrl=%2Fportal%2F",
    img: require("@/static/images/secondaryMenu/serviceChannel/Hand Tray.png"),
    title: "在线报价系统",
  },
  {
    link: "https://pts.allianz.com.cn/azclportal/faces/AgentLogin.jspx",
    img: require("@/static/images/secondaryMenu/serviceChannel/Shield House.png"),
    title: "营销员专区入口",
  },
  {
    link: "https://pts.allianz.com.cn/azclportal/faces/AgentLogin.jspx",
    img: require("@/static/images/secondaryMenu/serviceChannel/Mail.png"),
    title: "保全员入口",
  },
  {
    link: 'https://pts.allianz.com.cn/azclportal/faces/BankLogin.jspx',
    img: require("@/static/images/secondaryMenu/serviceChannel/Law Job.png"),
    title: '银行伙伴入口'
  },
  {
    link: "https://pts.allianz.com.cn/azclportal/faces/AgentLogin.jspx",
    img: require("@/static/images/secondaryMenu/serviceChannel/Law Key.png"),
    title: "银保服务专区入口",
  },
  {
    link: "https://pts.allianz.com.cn/azclportal/faces/AgentLogin.jspx",
    img: require("@/static/images/secondaryMenu/serviceChannel/Keys.png"),
    title: "经代服务专区入口",
  },
];
// 公开信息披露
export const disclosure = [
  {
    title: "基本信息",
    img: require("@/static/images/secondaryMenu/disclosure/Blog@2x.png"),
    twoMenu: [
      {
        title: "公司概况",
        children: [
          {
            link: `/azContent/introduce/${"AZ0070101"}`,
            text: "·公司概况",
          },
          {
            link: "/public/contactNumber",
            text: "·各分支机构营业场所和联系电话",
          },
        ],
      },
      {
        title: "公司治理概要",
        children: [
          {
            link: `/azContent/introduce/${"AZ0070201"}`,
            text: "·实际控制人及其控制本公司情况的简要说明",
          },
          {
            link: `/azContent/introduce/${"AZ0070202"}`,
            text: "·持股比例在5％以上的股东及其持股情况",
          },
          {
            link: `/azContent/introduce/${"AZ0070203"}`,
            text: "·近3年股东大会（股东会）决议",
          },
          {
            link: `/azContent/introduce/${"AZ0070206"}`,
            text: "·高级管理人员简历 、职责及其履职情况",
          },
          {
            link: `/azContent/introduce/${"AZ0070204"}`,
            text: "·董事简历",
          },
          {
            link: `/azContent/introduce/${"AZ0070205"}`,
            text: "·监事简历",
          },

          {
            link: `/azContent/introduce/${"AZ0070207"}`,
            text: "·公司部门设置情况",
          },
        ],
      },
      {
        link: `/public/publicDisclosure/${'AZ0070208'}`,
        title: "产品基本信息",
      },
    ],
  },
  {
    title: "年度信息",
    img: require("@/static/images/secondaryMenu/news/Calendar Travel.png"),
    twoMenu: [
      {
        title: "查看更多",
        link:`/public/publicDisclosure/${'AZ008'}`,
      }
    ]
  },
  {
    title: "重大事项",
    img: require("@/static/images/secondaryMenu/about/Partner Handshake.png"),
    twoMenu: [
      {
        title: "查看更多",
        link:`/public/publicDisclosure/${'AZ009'}`,
      }
    ]
  },
  {
    title: "专项信息",
    img: require("@/static/images/secondaryMenu/disclosure/Board Paper Note@2x.png"),
    twoMenu: [
      {
        title: "关联交易",
        children: [
          { link: `/public/publicDisclosure/${'AZ0100101'}`, text: "·逐笔披露" },
          { link: `/public/publicDisclosure/${'AZ0100102'}`, text: "·季度分类合并披露" },
        ],
      },
      {
        title: "资金运用",
        children: [
          { link: `/public/publicDisclosure/${'AZ0100201'}`, text: "·资金运用类关联交易" },
          { link: `/public/publicDisclosure/${'AZ0100202'}`, text: "·风险责任人信息" },
        ],
      },
      {
        title: "新型产品",
        link: "",
        children: [
          { link: "/disclosure", text: "·投连险信息披露" },
          { link: `/announcement`, text: "·万能险利率公告" },
          { link: `/azContent/particiInsurance/${'AZ01013'}`, text: "·分红险信息披露" },
        ],
      },
    ],
    menu2: [
      {
        text: "股东股权",
        link: `/public/publicDisclosure/${'AZ01004'}`,
      },
      {
        text: "短期健康保险",
        link: `/public/publicDisclosure/${'AZ01012'}`,
      },
      {
        text: "互联网保险",
        link: `/azContent/introduce/${'AZ01006'}`,
      },
      {
        text: "意外伤害保险",
        link: `/public/publicDisclosure/${'AZ01010'}`,
      },
      
      {
        text: "偿付能力",
        link: `/solvencyCapacity/${'AZ01005'}`,
      },
      // {
      //   text: "社会责任报告",
      //   link: `/public/publicDisclosure/${'AZ01011'}`,
      // },
      // {
      //   text: "长期医疗保险",
      //   link: `/azContent/introduce/${'AZ01006'}`,
      // },
      {
        text: "其他信息",
        link: `/public/publicDisclosure/${'AZ01007'}`,
      },
      {
        text: "健康管理服务",
        link: "/productsServices/health",
      }
      // {
      //   text: "cs",
      //   link: `/azContent/introduce/${'AZ01009'}`,
      // },
    ],
  }
];
export const enumsList = [
  {
    AZ001:"安联集团",
    AZ002:"安联在中国",
    AZ003:"安联人寿",

    AZ004:"营销员荣誉榜",
    AZ00401:"营销员荣誉榜-年度全国优秀营销员",
    AZ00402:"营销员荣誉榜-年度新晋升主管",
    AZ00403:"营销员荣誉榜-（资深）营业区总监",

    AZ005:"品牌奇迹",
    AZ00501:"品牌奇迹-全球“安联”球场家族",
    AZ00502:"品牌奇迹-欧洲豪门俱乐部",
    AZ00503:"品牌奇迹-安联探索营 青少年足球夏令营",
    AZ00504:"品牌奇迹-安联全球品牌大使郎朗",
    AZ00505:"品牌奇迹-纽约现代艺术博物馆",
    AZ00506:"品牌奇迹-无人机竞速",
    AZ00507:"品牌奇迹-FormulaE",
    AZ00508:"品牌奇迹-奥运会及残奥全球保险合作伙伴",
    AZ00509:"品牌奇迹-品牌奇迹",

    AZ006:"企业社会责任-绿色安联可持续发展",

    AZ011:"品牌荣誉",

    AZ007:"基本信息",
    AZ0070101:"基本信息-公司概况-公司概况",
    AZ0070102:"基本信息-公司概况-各分支机构营业场所和联系电话",

    AZ0070201:"基本信息-公司治理概要-实际控制人及其控制本公司情况的简要说明",
    AZ0070202:"基本信息-公司治理概要-持股比例在5％以上的股东及其持股情况",
    AZ0070203:"基本信息-公司治理概要-近3年股东大会（股东会）决议",
    AZ0070204:"基本信息-公司治理概要-董事简历",
    AZ0070205:"基本信息-公司治理概要-监事简历",
    AZ0070206:"基本信息-公司治理概要-高级管理人员简历 、职责及其履职情况",
    AZ0070207:"基本信息-公司治理概要-公司部门设置情况",
    AZ0070208:"基本信息-公司治理概要-产品基本信息",

    AZ008:"年度信息",
    AZ009:"重大事项",

    AZ010:"专项信息",
    AZ0100101:"专项信息-关联交易-逐笔披露",
    AZ0100102:"专项信息-关联交易-季度分类合并披露",

    AZ0100201:"专项信息-资金运用-资金运用类关联交易",
    AZ0100202:"专项信息-资金运用-风险责任人信息",

    AZ0100301:"专项信息-新型产品-投连险信息披露",
    AZ0100302:"专项信息-新型产品-万能险利率公告",
    AZ0100303:"专项信息-新型产品-分红险信息披露",

    AZ01004:"专项信息-股东股权",
    AZ01005:"专项信息-偿付能力",
    AZ01006:"专项信息-互联网保险",
    AZ01007:"专项信息-其他信息",
    AZ01008:"专项信息-健康管理服务",

    AZ012:"首页banner-轮播图",

    AZ013:"首页新闻banner",
    AZ01301:"首页新闻banner-上右",
    AZ01302:"首页新闻banner-下左",

    AZ014:"首页新闻",
    AZ01401:"首页新闻:上左",
    AZ01402:"首页新闻:下中",
    AZ01403:"首页新闻:上右",

    AZ015:"新闻公告",
    AZ01501:"新闻公告-公司新闻",
    AZ01502:"新闻公告-客服公告",
    AZ01503:"新闻公告-公司公告",
    AZ01504:"新闻公告-招标公告",

    AZ016:"文件素材",

    AZ017:"底部信息",
    AZ01701:"底部信息-联系我们",

    AZ01702:"底部信息-法律声明",
    AZ0170201:"底部信息-法律声明-使用注意事项",
    AZ0170202:"底部信息-法律声明-不可确信事项",
    AZ0170203:"底部信息-法律声明-链结网页事项",
    AZ0170204:"底部信息-法律声明-免责声明事项",
    AZ0170205:"底部信息-法律声明-隐私权保护事项",

    AZ01703:"底部信息-网上举报",
    AZ01704:"底部信息-反商业贿赂声明",
    AZ01705:"底部信息-消费者教育及风险提示专栏",

    AZ018:"个人客户",
    AZ01801:"个人客户-保险购买指南",
    AZ0180101:"个人客户-保险购买指南-在线销售渠道",

    AZ01802:"个人客户-保单售后服务",
    // AZ0180201:"个人客户-保单售后服务-客户信息保单信息类变更",
    // AZ0180202:"个人客户-保单售后服务-保单保障利益类变更",
    // AZ0180203:"个人客户-保单售后服务-投资产品/账户主合同变更",
    // AZ0180204:"个人客户-保单售后服务-红利及年金生存金类变更",
    // AZ0180205:"个人客户-保单售后服务-保全变更流程图",
    AZ0180206:"个人客户-保单售后服务-保险合同内容变更申请书下载",
    AZ0180207:"个人客户-保单售后服务-保单变更申请方式",
    AZ0180208:"个人客户-保单售后服务-保险交费方式",

    AZ01803:"个人客户-安联理赔服务",
    // AZ0180301:"个人客户-安联理赔服务-理赔服务承诺",
    // AZ0180302:"个人客户-安联理赔服务-保险事故的通知",
    // AZ0180303:"个人客户-安联理赔服务-理赔服务流程",
    // AZ0180304:"个人客户-安联理赔服务-理赔项目及所需材料下载",
    // AZ0180305:"个人客户-安联理赔服务-理赔意见箱",
    // AZ0180306:"个人客户-安联理赔服务-理赔问答Q&A",
    AZ0180307:"个人客户-安联理赔服务-理赔服务指南",
    AZ0180308:"个人客户-安联理赔服务-理赔常见问题",
    AZ0180309:"个人客户-安联理赔服务-理赔材料下载",

    AZ01804:"个人客户-核保体检机构查询",
    AZ0180401:"个人客户-核保体检机构查询-核保体检机构查询",
    AZ0180402:"个人客户-核保体检机构查询-通知",
    AZ0180403:"个人客户-核保体检机构查询-体检前注意事项",

    AZ01805:"个人客户-昂贵及质重医院查询",


    AZ019:"团体客户",
    AZ01901:"团体客户-保单售后服务",
    AZ0190101:"团体客户-保单售后服务-变更基本要求",
    AZ0190102:"团体客户-保单售后服务-团险保费定期结算",
    AZ0190103:"团体客户-保单售后服务-保全变更流程图",

    AZ01902:"团体客户-保单查询",
    AZ0190201:"团体客户-保单查询-电话查询",
    AZ0190202:"团体客户-保单查询-在线查询",
    AZ0190203:"团体客户-保单查询-客户服务中心",

    AZ020:"其他服务-投诉与建议",
    AZ02001:"投诉与建议-投诉受理流程图",
    AZ02002:"投诉与建议-投诉受理网点",
    AZ02003:"投诉与建议-投诉指引",

    AZ021:"投保提示书",

    AZ022:"隐私政策",

    AZ023:"图片素材",

    AZ024:"职业生涯",
    AZ02401:"职业生涯-代理人招聘",

    AZ025:"会员权益俱乐部",
    AZ02501:"会员权益俱乐部-贵宾俱乐部",
    AZ02502:"会员权益俱乐部-青年俱乐部",
    AZ02503:"会员权益俱乐部-少儿俱乐部",

    AZ026:"安联客服专线",
  }
]


// 省数据
export const province = {
  "110000": "北京",
"120000": "天津",
"130000": "河北省",
"140000": "山西省",
"150000": "内蒙古自治区",
"210000": "辽宁省",
"220000": "吉林省",
"230000": "黑龙江省",
"310000": "上海",
"320000": "江苏省",
"330000": "浙江省",
"340000": "安徽省",
"350000": "福建省",
"360000": "江西省",
"370000": "山东省",
"410000": "河南省",
"420000": "湖北省",
"430000": "湖南省",
"440000": "广东省",
"450000": "广西壮族自治区",
"460000": "海南省",
"500000": "重庆",
"510000": "四川省",
"520000": "贵州省",
"530000": "云南省",
"540000": "西藏自治区",
"610000": "陕西省",
"620000": "甘肃省",
"630000": "青海省",
"640000": "宁夏回族自治区",
"650000": "新疆维吾尔自治区",
"710000": "台湾省",
"810000": "香港特别行政区",
"820000": "澳门特别行政区",
"990000": "海外"
}
