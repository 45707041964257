<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
<!--    <router-view></router-view>-->
  </div>
<!--  <div id="app">-->
<!--    <transition name="slide-left" mode="out-in">-->
<!--      <router-view />-->
<!--    </transition>-->
<!--  </div>-->

</template>
<script>
export default {
  name: "app",
  // data(){
  //   return{
  //     include: []
  //   }
  // },
  // watch: {
  //   $route(to, from) {
  //     //如果 要 to(进入) 的页面是需要 keepAlive 缓存的，把 name push 进 include数组
  //     if (to.meta.keepAlive) {
  //       !this.include.includes(to.name) && this.include.push(to.name);
  //       console.log(this.include,"this.include")
  //     }
  //     //如果 要 form(离开) 的页面是 keepAlive缓存的，
  //     //再根据 deepth 来判断是前进还是后退
  //     //如果是后退
  //     if (from.meta.keepAlive && to.meta.deepth < from.meta.deepth) {
  //       var index = this.include.indexOf(from.name);
  //       index !== -1 && this.include.splice(index, 1);
  //     }
  //   }
  // }
    mounted: function () {
      //设置全局toast 3秒关闭
      this.$toast.setDefaultOptions({
          duration: 3000
      });
      this.$toast.setDefaultOptions("loading", {
          forbidClick: true
      });
    },

};
</script>

<style lang="less">
@import "~@/assets/less/_base.less";
//#app{
//  min-width: 1024px;
//}
//.slide-left-enter {
//  opacity: 0;
//  -webkit-transform: translate(100%, 0);
//  transform: translate(100%, 0);
//}
//.slide-left-enter-active{
//  transition: all .5s ease;
//}
//.slide-left-leave-to{
//  opacity: 0;
//  -webkit-transform: translate(-100%, 0);
//  transform: translate(-100%, 0);
//}
//.slide-left-leave-active {
//  transition: all .5s ease;
//}

</style>

<style lang="scss">
@import "~@/assets/scss/_base.scss";
</style>
