<template>
  <!-- 页脚 -->
  <div class="bottom">
    <footer>
      <div class="link">
        <!--        <ul>-->
        <!--          <li>帮助中心</li>-->
        <!--          <li>会员中心</li>-->
        <!--          <li>保单续保</li>-->
        <!--          <li>理赔服务</li>-->
        <!--          <li>资料下载</li>-->
        <!--          <li>常见问题</li>-->
        <!--          <li>隐私政策</li>-->
        <!--        </ul>-->
        <!--        <ul>-->
        <!--          <li>安联品牌</li>-->
        <!--          <li>品牌故事</li>-->
        <!--          <li>品牌宗旨</li>-->
        <!--          <li>偿付能力信息披露</li>-->
        <!--          <li>消费者保护监管评价</li>-->
        <!--          <li>公开信息披露</li>-->
        <!--          <li>互联网保险信息披露</li>-->
        <!--        </ul>-->
        <!--        <ul>-->
        <!--          <li>友情链接</li>-->
        <!--          <li>安联集团</li>-->
        <!--          <li>银保监会</li>-->
        <!--          <li>安联亚太</li>-->
        <!--        </ul>-->
        <ul>
          <li v-for="(item, index) in footer" :key="index">
            <template v-if="htt(item.link)">
              <a :href="item.link" target="_blank">
                {{ item.title }}
              </a>
            </template>
            <template v-else>
              <a @click="jumpTo(item.link)">{{ item.title }}</a>
            </template>
          </li>

          <!--          <li>法律声明</li>-->
          <!--          <li>安联中国</li>-->
          <!--          <li>网上举报</li>-->
          <!--          <li>反商业贿赂声明</li>-->
          <!--          <li>消费者教育及风险提示专栏</li>-->
          <!--          <li>中国保险行业协会人身保险产品查询</li>-->
        </ul>
      </div>
      <div class="public">
        <div class="public_pic">
          <img src="@/static/images/ic_logo_bottom@2x.png" alt="" />
          <div>
            <div>
              <img src="~@/static/images/public.jpg" alt="" />
            </div>
            <p>公众号-安联人寿</p>
          </div>
          <div>
            <div>
              <img src="~@/static/images/service.jpg" alt="" />
            </div>
            <p>服务号-安联在线</p>
          </div>
        </div>
        <div class="footer">
          © 安联人寿保险有限公司 2012. All Rights Reserved.
          <br /><a href="https://beian.miit.gov.cn/" target="_blank">
            <img src="@/static/images/gh.png" alt="" />
            沪ICP备07011309号</a>
            <p><a href="https://beian.mps.gov.cn/#/"  target="_blank">沪公网安备31011502401622号</a></p>
            <p>该网站已支持IPv6</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
const footer = [
  {
    title: "联系我们",
    link: `/azContent/introduce/${"AZ01701"}`,
  },
  {
    title: "安联集团",
    link: "https://www.allianz.com/en.html",
  },
  {
    title: "法律声明",
    link: `/personalCustomer/insuranceAfterSales/${"AZ01702"}`,
  },

  {
    title: "网上举报",
    link: `/azContent/introduce/${"AZ01703"}`,
  },
  {
    title: "反商业贿赂声明",
    link: `/azContent/introduce/${"AZ01704"}`,
  },
  {
    title: "消费者教育及风险提示专栏",
    link: `/consumer`,
  },
  {
    title: "普惠金融推进月专栏",
    link: `/phFinancialPromotion`,
  },
  {
    title: "中国保险行业协会人身保险产品查询",
    link: "http://www.iachina.cn/art/2017/6/29/art_71_45682.html",
  },
];
export default {
  name: "footerPage",
  data() {
    return {
      footer,
    };
  },
  methods: {
    htt(str) {
      var reg = /^(http|https|www):\/\/.*/g;
      // let reg = /(http|https):\/\/([\w.]+\/?)\S*/ig

      let flag = reg.test(str);
      // console.log(flag)
      return flag;
    },
    jumpTo(menu) {
      let reg = /^\/azContent\/introduce\/.*/g;
      console.log(menu);
      reg.test(menu)
        ? sessionStorage.setItem("MENU", "底部信息")
        : this.$router.push(menu);
      this.$router.push(menu);
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: rgb(62, 62, 62);
}
.bottom {
  width: 100%;
  border-top: 1px solid #ccc;
  footer {
    width: 80vw;
    margin: auto;
    font-size: 1.8rem;
    display: flex;
    justify-content: space-between;
    padding: 5rem 0;
    box-sizing: border-box;
    text-align: center;
    .link {
      display: flex;
      width: 49%;
      justify-content: space-between;
      //margin-left: 10rem;
      > ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        font-size: 2rem;
        align-content: space-evenly;
        li {
          height: 4rem;
          line-height: 4rem;
          margin: 0 6rem 0 0;
          &:hover {
            > a {
              cursor: pointer;
              color: #003781;
            }
          }
        }
        > li:first-child {
          font-size: 2rem;
        }
      }
    }
    .public {
      width: 30vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .public_pic {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > img {
          width: 16rem;
          //height: 7rem;
          margin-bottom: 4rem;
        }
        > div {
          > div {
            width: 18rem;
            height: 18rem;
            background-color: #abcdef;
            > img {
              width: 100%;
              height: 100%;
            }
          }
          > p {
            text-align: center;
            margin: 1rem 0;
          }
        }
      }
    }
    .footer {
      font-size: 1.8rem;
       text-align: right;
      a {
        color: #003781;
        display: flex;
        justify-content: right;
        align-items: center;
        > img {
          height: 2rem;
          margin: 0 0.5rem;
        }
      }
    }
  }
}
</style>
