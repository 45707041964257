import http from "@/utils/request.js";

//财富增值
export function productList(data) {
  return http({
    url: "/product/list",
    method: "post",
    data,
  });
}
//财富增值---预览
export function productListPre(data) {
  return http({
    url: "/product/preList",
    method: "get",
    params: data,
  });
}

// 详情
export function productListDetails(data) {
  return http({
    url: "/product/getProductInfo",
    method: "post",
    data
  });
}
// 详情---预览
export function productListDetailsPre(data) {
  return http({
    url: "/product/getPreProductInfo",
    method: "get",
    params: data,
  });
}
// banner
export function getProductCategory(data) {
  return http({
    url: "/productCategory/list",
    method: "post",
    data,
  });
}
