<template>
  <div class="header">
    <header>
      <img
        @click="jumpTo('/home')"
        src="~@/static/images/ic_logo_bottom@2x.png"
        alt=""
        title="回首页"
      />
      <img @click="jumpTo('/olympicGames')" src="~@/static/images/logo1107.png" alt="" />
    </header>
  </div>
</template>

<script>
export default {
  name: "top",
  methods: {
    jumpTo(menu) {
      this.$router.push(menu);
    },

  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
/* 页头logo */
.header {
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;

  header {
    width: 80vw;
    height: 7rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > img:first-child {
      width: 14rem;
      cursor: pointer;
      // height: 6.5rem;
    }
    > img:last-child {
      // width: 25rem;
      // height: 6.5rem;
      height: 100%;
      cursor: pointer;

    }
  }
}
</style>